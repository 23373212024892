import {useState, useEffect, useCallback} from 'react'
import axios from 'axios'

import useLocalStorage from './useLocalStorage'
import {API_URL} from '../../config'

const useFetch  = (url) => {



  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [options, setOptions] = useState({})
  const [token] = useLocalStorage('token')

  const doFetch = useCallback((options = {}) => {
    setOptions(options)
    setIsLoading(true)
    setError(null)
    setResponse(null)
  }, [])

  useEffect(() => {
    let skipGetResponseAfterDestroy = false
    if (!isLoading) {
      return
    }

    const requestOptions = {
      ...options,
      ...{
        headers: {
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    }

    axios(API_URL + url, requestOptions)
      .then(res => {
        if (!skipGetResponseAfterDestroy) {
          setResponse(res.data)
          setIsLoading(false)
        }
      })
      .catch(error => {
        if (!skipGetResponseAfterDestroy) {
          const err = (error.response === undefined)? error.message : error.response.data.detail
          setIsLoading(false)
          setError(err)

          if (error.response && error.response.status === 401 && window.location.href.indexOf('/login') === -1) window.location.href='/login'

        }
      })
    return () => {
      skipGetResponseAfterDestroy = true
    }
  }, [isLoading, url, options, token])

  return [{isLoading, response, error}, doFetch]
}

export default useFetch