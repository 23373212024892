import React, { Suspense} from 'react'
import { Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import './scss/kendo-theme-custom.scss'
import './scss/style.scss'

import {
    IntlProvider,
    load,
    loadMessages,
    LocalizationProvider,
} from "@progress/kendo-react-intl";



import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/ru/numbers.json";
import caGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ru/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ru/timeZoneNames.json";

import {CurrentUserProvider} from './components/auth/СurrentUser'
import CurrentUserChecker from './components/auth/СurrentUserChecker'

import ruMessages from "./layout/ru.json"

loadMessages(ruMessages, "ru")

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames
);


const loading = (
    <div className="pt-3 text-center">
        {/*<div className="sk-spinner sk-spinner-pulse"></div>*/}
    </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./components/auth/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
    return (
        <Router>
            <LocalizationProvider language="ru">
            <IntlProvider locale={'ru'}>
                <CurrentUserProvider>
                    <CurrentUserChecker>
                        <Suspense fallback={loading}>
                            <Routes>
                                <Route exact path="/login" name="Login Page" element={<Login/>}/>
                                <Route exact path="/register" name="Register Page" element={<Register/>}/>
                                <Route exact path="/404" name="Page 404" element={<Page404/>}/>
                                <Route exact path="/500" name="Page 500" element={<Page500/>}/>
                                <Route path="*" name="Home" element={<DefaultLayout/>}/>
                            </Routes>
                        </Suspense>
                    </CurrentUserChecker>
                </CurrentUserProvider>
            </IntlProvider>
            </LocalizationProvider>
        </Router>
    )
}


export default App

