import {useState, useEffect} from 'react'

export default (key, initialValue = '') => {
    const [value, setValue] = useState(() => {
        if (typeof initialValue === 'string' || initialValue instanceof String)
            return localStorage.getItem(key) || initialValue
        else
            return (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : initialValue
    })

    useEffect(() => {
        if (typeof initialValue === 'string' || initialValue instanceof String)
            localStorage.setItem(key, value)
        else
            localStorage.setItem(key, JSON.stringify(value))
    }, [value, key])

    return [value, setValue]
}
