import {useEffect, useContext} from 'react'


import useFetch from '../hooks/useFetch'
import {CurrentUserContext} from './СurrentUser'
import useLocalStorage from '../hooks/useLocalStorage'

const CurrentUserChecker = ({children}) => {
    const [, dispatch] = useContext(CurrentUserContext)
    const [{response, error}, doFetch] = useFetch('/users/me')
    const [token] = useLocalStorage('token')


    useEffect(() => {
        if (!token) {
            dispatch({type: 'SET_UNAUTHORIZED'})
            if (window.location.href.indexOf('/login') === -1) window.location.href = '/login'
            return
        }

        doFetch()
        dispatch({type: 'LOADING'})
    }, [doFetch, dispatch, token])

    useEffect(() => {
        if (error) {
            if (window.location.href.indexOf('/login') === -1) window.location.href = '/login'
            return
        }

        if (!response) {
            //     window.location.href='/login'
            return
        }


        dispatch({type: 'SET_AUTHORIZED', payload: response.user})
    }, [response, dispatch, error])


    return children

}

export default CurrentUserChecker
