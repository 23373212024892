//export const API_URL = 'http://localhost:8000'
//export const API_URL = 'https://95.163.242.39:8000'
export const API_URL = 'https://api.mountrim.ru:8000'
export const NETWORK_DELAY = 10   //НОРМ ЗАДЕРЖКА СЕТИ В МИН
export const VIK_BROCKEN = 0.05   //доля рейсов с неработающим вик
export const UNDEF_HAULS = 0.05   //доля неопределенных рейсов
export const PLAYER_STEP = 1   //ШАГ ПЛЕЕРА В МИН

export function getShiftTime(mineId, shiftId) { // расчет начала смен по рудникам
    let SHIFT_START_TIME
    let SHIFT_END_TIME
    let SHIFT_DURATION_HOURS
    let TIMESHIFT_DAYS  //если начало смены в предыдущей дате = 1 иначе 0

    if (+mineId === 1) { // маяк 8 часовые смены
        switch (+shiftId) {
            case 1:
                SHIFT_START_TIME = '01:00'
                SHIFT_END_TIME = '09:00'
                SHIFT_DURATION_HOURS = 8
                TIMESHIFT_DAYS = 0
                break
            case 2:
                SHIFT_START_TIME = '09:00'
                SHIFT_END_TIME = '17:00'
                SHIFT_DURATION_HOURS = 8
                TIMESHIFT_DAYS = 0
                break
            case 3:
                SHIFT_START_TIME = '17:00'
                SHIFT_END_TIME = '01:00'
                SHIFT_DURATION_HOURS = 8
                TIMESHIFT_DAYS = 1
                break
        }
    } else { // 12 часовые смены
        switch (+shiftId) {
            case 1:
                SHIFT_START_TIME = '21:00'
                SHIFT_END_TIME = '09:00'
                SHIFT_DURATION_HOURS = 12
                TIMESHIFT_DAYS = 1
                break
            case 2:
                SHIFT_START_TIME = '09:00'
                SHIFT_END_TIME = '21:00'
                SHIFT_DURATION_HOURS = 12
                TIMESHIFT_DAYS = 0
                break
        }
    }

    return {
        SHIFT_START_TIME: SHIFT_START_TIME,
        SHIFT_END_TIME: SHIFT_END_TIME,
        SHIFT_DURATION_HOURS: SHIFT_DURATION_HOURS,
        TIMESHIFT_DAYS: TIMESHIFT_DAYS
    }

}
